import React from 'react';
import SignupForm from './signupForm';
import HeroImg from './../images/image-Hero.png';

export const Hero = () => {
  return (
    <div className="relative min-h-screen pt-20">
      <img
        src={HeroImg}
        className="absolute inset-0 object-cover w-full h-full"
        alt=""
      />
      <div className="relative ">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="flex flex-col items-center justify-between lg:flex-row">
            <div className="max-w-2/5 mb-12 xl:mb-0 xl:pr-16 xl:w-2/5">
              <h2>
                <span className="bg-ocPink inline-block py-2 mb-2 text-5xl tracking-widest font-ocTheme font-normal text-ocBlue md:text-6xl md:leading-none">Your home</span>
                <span className="inline-block py-2 m-0 mb-6 text-5xl tracking-widest font-ocTheme font-normal text-ocPink md:text-6xl md:leading-none">Funded Collectively</span>
              </h2>
              <p className="max-w-xl mb-4 text-base text-gray-400 md:text-lg">
                We can get by with a little help from our friends. And families. And uncles. Even that random bloke from down the road. With support you can to make your dream home come true right now. 
                <br /><br />You tell us what you need - we'll do the rest.
              </p>
              <a
                href="/calculator"
                aria-label=""
                className="invisible mt-4 inline-flex items-center font-semibold tracking-wider justify-center px-4 py-2 text-sm font-light tracking-wide text-white transition-colors duration-500 rounded-full hover:bg-ocTeal hover:text-gray-900 outline outline-ocTeal outline-offset-2 outline-2"
              >
                Show me how
                <svg
                  className="inline-block w-3 ml-2"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
              </a>
            </div>
            <div className="flex w-full flex-row items-center justify-center lg:justify-end max-w-3/5 mb-12 xl:mb-0 xl:w-3/5">
              <SignupForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};