import React from 'react';
import { Helmet } from "react-helmet"

export const Head = () => {

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Owned Collectively - home financing reimagined</title>
            <link rel="canonical" href="https://ownedcollectively.com/" />
            <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/25953641.js"></script>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href={"https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;1,300&display=swap"} rel="stylesheet"></link>
            <meta property="og:title" content="Owned Collectively - home financing reimagined" />
            <meta property="og:url" content="https://ownedcollectively.com/" />
            <meta
                property="og:image"
                content="https://ownedcollectively.com/static/image-Hero-b4e8d85bd8f710f3f3dada351f73be59.png"
            />
        </Helmet>
    )
}