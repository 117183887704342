import React, { useState } from 'react';

const INITAL_INTREST_RATE = 0.0534;
const SUBSEQUENT_INTREST_RATE = 0.0559;

export const Remortgage = () => {

  const [example, setExample] = useState(true);

  const handleSwapExample = () => {
    setExample(!example);
  }

  const collectiveSupport_firstTime = {
    bankLoan: 278,
    bankLoanHeight: 43,
    deposit: 78,
    depositHeight: 12,
    missingCapital: 0,
    missingCapitalHeight: 0,
    rates: {
      averageMonthlyPayments: 1898,
      initialMonthlyPaymentsRounded: 1683,
      initialRateMonthsCount: 63,
      rentPayment: 182,
      subsequentMonthlyPaymentsRounded: 1725,
      subsequentRateMonthsCount: 237
    },
    sharedEquityAmount: 292,
    sharedEquityHeight: 45
  }
  
    
    const soloData_firstTime = {
      bankLoan: 279,
      bankLoanHeight: 43,
      deposit: 78,
      depositHeight: 12,
      missingCapital: 291,
      missingCapitalHeight: 45,
      missingIncome: 65,
      rates: {
        averageMonthlyPayments: 3513,
        initialMonthlyPaymentsRounded: 3446,
        initialRateMonthsCount: 63,
        rentPayment: 0,
        subsequentMonthlyPaymentsRounded: 3531,
        subsequentRateMonthsCount: 237
      }
    }


    let exampleSoloData = soloData_firstTime;
    let exampleCollective = collectiveSupport_firstTime;
    let heading = ["Get your", "dream home"];
    let calculatorOptionsHeading = "Traditional purchase";

    if(!example) {
      exampleSoloData = soloData_firstTime;
      exampleCollective = collectiveSupport_firstTime;
      heading = ["Reduce your", "monthly payments"];
      calculatorOptionsHeading = "Traditional remortgage";
    }

    return (
      <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-8 bg-gray-200">
        <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="max-w-xl">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-ocLightCyan">
                  Mortgage &amp; Remortgage
                </p>
              </div>
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                {heading[0]}{' '} <br />        
                <span className="inline-block text-ocTeal">
                {heading[1]}
                </span>
              </h2> 
              <p className="text-base mb-4 text-gray-700 md:text-md">
                Seven out of ten first time buyers have been priced out of the property market and those who can make it are often stretched to the limit.
              </p>
              {/*<p className="text-base mb-4 text-gray-700 md:text-md">
                  Choose an example scenario to see how much we could save you.
              </p>*/}
              <div className="flex flex-row justify-start">
                <p className="p-2 text-base text-ocPink md:text-sm bg-ocDarkBlue cursor-pointer" onClick={handleSwapExample}>
                    Avg. London first time buyers
                </p>
                {/*< p className="p-2 text-base text-ocBlue md:text-sm cursor-pointer hover:bg-ocDarkBlue hover:text-ocPink" onClick={handleSwapExample}>
                    Avg. remortgage
    </p>*/}
              </div>
              <div className="border-2 mb-8 border-ocDarkBlue flex flex-col lg:flex-row justify-around">
                {(example) ?
                <> 
                  <div className="m-4 bg-ocPink flex flex-col items-center">
                    <p className="p-2 text-base text-4xl">£648,000</p>
                    <p className="p-2 text-base text-sm">Avg. Home cost</p>
                  </div>
                  <div className="m-4 bg-ocPink flex flex-col items-center">
                    <p className="p-2 text-base text-4xl">£61,920</p>
                    <p className="p-2 text-base text-sm">Avg. Income</p>
                  </div>
                  <div className="m-4 bg-ocPink flex flex-col items-center">
                    <p className="p-2 text-base text-4xl">£78,000</p>
                    <p className="p-2 text-base text-sm">Avg. Deposit</p>
                  </div>
                </>
                :
                <>
                  <div className="m-4 bg-ocPink flex flex-col items-center">
                    <p className="p-2 text-base text-4xl">£193,000</p>
                    <p className="p-2 text-base text-sm text-center">Avg. mortgage balance</p>
                  </div>
                  <div className="m-4 bg-ocPink flex flex-col items-center">
                    <p className="p-2 text-base text-4xl">4.5%</p>
                    <p className="p-2 text-base text-sm text-center">Avg. remortgage interest rate</p>
                  </div>
                  <div className="m-4 bg-ocPink flex flex-col items-center">
                    <p className="p-2 text-base text-4xl">£400</p>
                    <p className="p-2 text-base text-sm text-center">Avg. monthly payment increase</p>
                  </div>
                </>
                }
              </div>

            </div>
            <a
                className="w-64 inline-flex items-center font-semibold tracking-wider justify-center px-4 py-2 text-sm font-light tracking-wide text-ocBlue transition-colors duration-500 rounded-full hover:bg-ocTeal hover:text-gray-900 outline outline-ocTeal outline-offset-2 outline-2"
                href="/calculator"  
            >
                Try your own calculation 
                <svg
                className="inline-block w-3 ml-2"
                fill="currentColor"
                viewBox="0 0 12 12"
                >
                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
            </a>
          </div>
          <div className="">
            <div className="flex flex-col lg:flex-row items-start">
                <div className="w-full lg:w-1/2 rounded drop-shadow-xl bg-white p-4 lg:mr-4 mb-4 lg:mb-0">
                    <h3 className="pt-2 text-lg font-light text-ocBlue border-b border-gray">{calculatorOptionsHeading}</h3>
                    <div className="flex flex-row">
                        <div className="flex flex-col justify-center items-center w-2/5 h-48 mt-8 border-b border-ocDarkBlue">
                            { exampleSoloData.missingCapital > 0 &&
                            <div className={`flex justify-center items-center w-4/6 h-1/5 bg-red-400 border-t-4 border-l-4 border-r-4 border-orange-200 `} style={{height : `${exampleSoloData.missingCapitalHeight}%`}}>
                                <p className="text-sm text-gray-600 font-bold">{exampleSoloData.missingCapital}k</p>
                            </div>
                            }
                            <div className={`flex justify-center items-center w-4/6 h-3/5 bg-orange-200`} style={{height : `${exampleSoloData.bankLoanHeight}%`}}>
                                <p className="text-sm text-gray-600 font-bold">{exampleSoloData.bankLoan}k</p>
                            </div>
                            <div className={`flex justify-center items-center w-4/6 h-1/5 bg-orange-300`} style={{height : `${exampleSoloData.depositHeight}%`}}>
                                <p className="text-sm text-gray-600 font-bold">{exampleSoloData.deposit}k</p>
                            </div>
                            
                        </div>
                        <div className="flex flex-col justify-center w-3/5">
                            { exampleSoloData.missingCapital > 0 &&
                            <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                <span className="block w-3 h-3 bg-red-400 mr-2"/>
                                Unavailable capital based on income
                            </div>
                            }
                            <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                <span className="block w-3 h-3 bg-orange-200 mr-2"/>
                                Bank loan
                            </div>
                            <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                <span className="block w-3 h-3 bg-orange-300 mr-2"/>
                                Your deposit
                            </div> 
                        </div>
                    </div>
                    <div className="flex w-full">
                        { (exampleSoloData.missingCapital > 0) ?
                        <div className="flex flex-col mx-2 my-6">
                            <p className="text-4xl text-black font-bold text-red-600">£{exampleSoloData.rates.averageMonthlyPayments}</p>
                            <p className="text-sm text-gray-600 font-bold">Average monthly spend</p>
                            <p className="text-xs text-gray-400">*  {exampleSoloData.rates.initialRateMonthsCount} months of £{exampleSoloData.rates.initialMonthlyPaymentsRounded} at {(INITAL_INTREST_RATE*100).toFixed(2)}%</p>
                            <p className="text-xs text-gray-400">*  {exampleSoloData.rates.subsequentRateMonthsCount} months of £{exampleSoloData.rates.subsequentMonthlyPaymentsRounded} at {(SUBSEQUENT_INTREST_RATE*100).toFixed(2)}%</p>
                            <p className="text-xs text-red-400 w-2/3">Income needs to be £{exampleSoloData.missingIncome}k higher to qualify for the full loan.</p>
                        </div>
                        :
                        <div className="flex flex-col mx-2 my-6">
                            <p className="text-4xl text-black font-bold">£{exampleSoloData.rates.averageMonthlyPayments}</p>
                            <p className="text-sm text-gray-600 font-bold">Average monthly spend</p>
                        </div>
                        }

                    </div>
                </div>
                <div className="w-full lg:w-1/2 rounded drop-shadow-xl bg-white p-4 lg:mr-4 mb-4 lg:mb-0">
                      <div className="pt-2 flex border-b border-gray justify-left">
                        <h3 className="text-lg font-light text-ocBlue">Owned Collectively</h3>
                      </div>
                      <div className="flex flex-row">
                          <div className="flex flex-col justify-center items-center w-2/5 h-48 mt-8 border-b border-ocDarkBlue">
                              { exampleCollective.missingCapital > 0 &&
                              <div className="flex justify-center items-center w-4/6 h-1/6 bg-red-400 transition-height" style={{height : `${exampleCollective.missingCapitalHeight}%`}}>
                                  <p className="text-sm text-gray-600 font-bold">{exampleCollective.missingCapital}k</p>
                              </div>
                              }
                              <div className="flex justify-center items-center w-4/6 h-4/5 bg-indigo-200 transition-height" style={{height : `${exampleCollective.bankLoanHeight}%`}}>
                                  <p className="text-sm text-gray-600 font-bold">{exampleCollective.bankLoan}k</p>
                              </div>
                              <div className="flex justify-center items-center w-4/6 h-1/5 bg-violet-300 transition-height" style={{height : `${exampleCollective.sharedEquityHeight}%`}}>
                                  <p className="text-sm text-gray-600 font-bold">{exampleCollective.sharedEquityAmount}k</p>
                              </div>
                              <div className="flex justify-center items-center w-4/6 h-1/5 bg-blue-300 transition-height" style={{height : `${exampleCollective.depositHeight}%`}}>
                                  <p className="text-sm text-gray-600 font-bold">{exampleCollective.deposit}k</p>
                              </div>
                          </div>
                          <div className="flex flex-col justify-center w-3/5">
                              { exampleCollective.missingCapital > 0 &&
                              <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                  <span className="block w-3 h-3 bg-red-400 mr-2"/>
                                  Missing capital
                              </div>
                              }
                              <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                  <span className="block w-3 h-3 bg-indigo-200 mr-2"/>
                                  Bank loan
                              </div>
                              <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                  <span className="block w-3 h-3 bg-violet-300 mr-2"/>
                                  Collective equity
                              </div>
                              <div className="flex justify-start items-start text-xs text-gray-60 mb-4" >
                                  <span className="block w-3 h-3 bg-blue-300 mr-2"/>
                                  Your deposit
                              </div> 
                          </div>
                      </div>
                      <div className="flex w-full">
                          { (exampleCollective.missingCapital > 0) ?
                          <div className="flex flex-col mx-2 my-6">
                              <p className="text-4xl text-black font-bold text-red-400">-£{exampleCollective.missingCapital}k</p>
                              <p className="text-sm text-gray-600 font-bold text-red-400">Purchase needs increased collective equity</p>
                          </div>
                          :
                          <div className="flex flex-col mx-2 my-6">
                              <p className="text-4xl text-black font-bold">£{exampleCollective.rates.averageMonthlyPayments}</p>
                              <p className="text-sm text-gray-600 font-bold">Average monthly spend*</p>
                              <p className="text-xs text-gray-400">*  {exampleCollective.rates.initialRateMonthsCount} months of £{exampleCollective.rates.initialMonthlyPaymentsRounded} at {(INITAL_INTREST_RATE*100).toFixed(2)}%</p>
                              <p className="text-xs text-gray-400">*  {exampleCollective.rates.subsequentRateMonthsCount} months of £{exampleCollective.rates.subsequentMonthlyPaymentsRounded} at {(SUBSEQUENT_INTREST_RATE*100).toFixed(2)}%</p>
                              <p className="text-xs text-gray-400 w-2/3">including max £{exampleCollective.rates.rentPayment} monthly rental payment to equity owners</p>

                          </div>
                          }
                          
                      </div>
                  </div>
                </div>

          </div> 
        </div>
      </div>
    );
  };