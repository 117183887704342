import React, { useState, useEffect } from 'react';
import { unstable_batchedUpdates } from "react-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: 'Years'
      }
    },
    y: {
      display: true,
      title: {
        display: true,
        text: 'Value £'
      }
    }
  }

};

const labels = [];
const bank_datapoints = [];
const total_datapoints = [];

const initialChartData = {
  labels: labels,
  datasets: [
    {
      label: 'Average Bank Account',
      pointStyle: false,
      data: bank_datapoints,
      borderColor: "rgb(254, 215, 170)",
      fill: false,
      cubicInterpolationMode: 'monotone',
      tension: 0.4
    },
    {
      label: 'Owned Collectively',
      pointStyle: false,
      data: total_datapoints,
      borderColor: "rgba(196, 181, 253, 0.8)",
      backgroundColor: "rgba(196, 181, 253, 0.8)",
      fill: true,
      cubicInterpolationMode: 'monotone',
      tension: 0.4
    }
  ]
};

export const InvestorSimulation = () => {

  const [propertyData, setPropertyData] = useState({});
  const [bankRates, setBankRates] = useState({});

  const [investmentAmount, setInvestmentAmount] = useState(5000);
  const [investmentPeriod, setInvestmentPeriod] = useState(10);
  const [investmentLocation, setInvestmentLocation] = useState("Canterbury");

  const [chartData, setChartData] = useState({...initialChartData});

  useEffect(() => {
    fetch(`data/combinedData.json`)
      .then(response => response.json())
      .then(resultData => {
        
        unstable_batchedUpdates(() => {
          setPropertyData(resultData.propertyData);
          setBankRates(resultData.bankRates);
          calculateInvestmentData(resultData.propertyData, resultData.bankRates, investmentLocation, investmentAmount, investmentPeriod);
        });
        
      })
  }, []);

  const calculateInvestmentData = function(propertyData, bankRates, investmentLocation, investmentAmount, investmentPeriod) {
    const historicalData = propertyData[investmentLocation];
    const arithmeticMeanData = [];
    const arithmeticMeanLabels = [];
    Object.keys(historicalData.threeMonthMovingArithmeticMean).forEach(item => {
      arithmeticMeanData.push(historicalData.threeMonthMovingArithmeticMean[item]);
      arithmeticMeanLabels.push(item.split("-")[0]);
    })
    const arithmeticMeanDataSubSet = arithmeticMeanData.slice(-investmentPeriod);
    const arithmeticMeanLabelsSubSet = arithmeticMeanLabels.slice(-investmentPeriod);

    let temporaryInvestmentValueStore = investmentAmount;
    const newDataSet = arithmeticMeanDataSubSet.map((value, i) => {
      const previousAverage = arithmeticMeanDataSubSet[i-1];
      if(previousAverage) {
        const percentageChange = value/previousAverage;
        const nextInvestmentAmount = Math.round(temporaryInvestmentValueStore*percentageChange);
        temporaryInvestmentValueStore = nextInvestmentAmount;
        return nextInvestmentAmount;
      } else {
        return investmentAmount;
      }
    });

    const newChartData = {...initialChartData};
    newChartData.labels = arithmeticMeanLabelsSubSet;
    newChartData.datasets[1].data = newDataSet;
    newChartData.datasets[0].data = calculateBankInvestment(bankRates, investmentAmount, investmentPeriod);
    setChartData(newChartData);

  }

  const calculateBankInvestment = function(bankRates, investmentAmount, investmentPeriod) {
    const interestRateMeanData = [];
    Object.keys(bankRates).forEach(year => {
      interestRateMeanData.push(bankRates[year]);
    })
    const interestRateMeanDataSubSet = interestRateMeanData.slice(-investmentPeriod);

    let temporaryInvestmentValueStore = investmentAmount;
    const newDataSet = interestRateMeanDataSubSet.map(interestRate => {
      const investmentAmountWithInterest = Math.round(temporaryInvestmentValueStore + (temporaryInvestmentValueStore*(Number(interestRate)/100)));
      temporaryInvestmentValueStore = investmentAmountWithInterest;
      return investmentAmountWithInterest;
    });

    newDataSet.shift();
    newDataSet.unshift(investmentAmount);

    return newDataSet;

  }

  const handleSwapLocation = (e) => {
    const newLocation = e.target.value;
    if(propertyData[newLocation]) {
      setInvestmentLocation(newLocation);
      calculateInvestmentData(propertyData, bankRates, newLocation, investmentAmount, investmentPeriod);
    }
  }

  const handleUpdatePeriod = (e) => {
    const newPeriod = e.target.value;
    setInvestmentPeriod(newPeriod);
    calculateInvestmentData(propertyData, bankRates, investmentLocation, investmentAmount, newPeriod);
  }

  const handleUpdateAmount = (e) => {
    const newAmount= Number(e.target.value);
    setInvestmentAmount(newAmount);
    calculateInvestmentData(propertyData, bankRates, investmentLocation, newAmount, investmentPeriod);
  }

  let bankReturnPercent = 0,
      OCReturnPercent = 0;

  if(chartData.datasets[0].data[chartData.datasets[0].data.length - 1]) {
    bankReturnPercent = Math.round(((chartData.datasets[0].data[chartData.datasets[0].data.length - 1]-investmentAmount)/investmentAmount)*100);
    OCReturnPercent = Math.round(((chartData.datasets[1].data[chartData.datasets[1].data.length - 1]-investmentAmount)/investmentAmount)*100);
  }

  return (
    <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-8 bg-gray-200">
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-ocLightCyan">
                Investing for the future
              </p>
            </div>
            <h2 className="max-w-lg mb-4 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Support friends and family<br />
              <span className="inline-block text-ocTeal">
                with a solid investment
              </span>
            </h2>
            <p className="text-base mb-6 text-gray-700 md:text-md">
              Play with the values to see how your capital could have grown.
            </p>
            <div className="flex flex-row justify-start">
              <p className="p-2 text-base text-ocPink md:text-sm bg-ocDarkBlue cursor-pointer">
                Your investment
              </p>
            </div>
            <div className="border-2 mb-8 border-ocDarkBlue flex flex-col lg:flex-row flex-wrap justify-between">
              <div className="m-4 p-2 bg-ocPink flex flex-col items-center grow">
                <p className="text-base text-sm">a</p>
                <div className="flex flex-row items-center">
                  <p className="text-base text-4xl mr-2">£</p>
                  <input 
                      type="number"
                      value={investmentAmount}
                      onChange={handleUpdateAmount}
                      className="text-base text-4xl bg-transparent w-32"
                  />
                </div>
                <p className="pt-2 text-base text-sm">Investment</p>
              </div>
              <div className="m-4 p-2 bg-ocPink flex flex-col items-center grow">
                <p className="text-base text-sm">over the last</p>
                <select 
                  className="text-base text-4xl bg-transparent"
                  onChange={handleUpdatePeriod}
                  value={investmentPeriod}
                >
                  <option>2</option>
                  <option>5</option>
                  <option>10</option>
                  <option>15</option>
                  <option>20</option>
                  <option>25</option>
                </select>
                <p className="pt-2 text-base text-sm">years</p>
              </div>
              <div className="m-4 p-2 bg-ocPink flex flex-col items-center grow">
                <datalist id="countrydata">
                  {Object.keys(propertyData).map(key => <option key={key}>{key}</option>)}
                </datalist>
                <p className="text-base text-sm">in the</p>
                <input
                  type="text"
                  list="countrydata"
                  id="country"
                  name="country"
                  defaultValue={"Canterbury"}
                  autoComplete="off"
                  className="w-full text-base text-4xl bg-transparent text-center"
                  onChange={handleSwapLocation}
                />
                <p className="pt-2 text-base text-sm">area</p>
              </div>



            </div>

          </div>
          <a
            className="w-64 inline-flex items-center font-semibold tracking-wider justify-center px-4 py-2 text-sm font-light tracking-wide text-ocBlue transition-colors duration-500 rounded-full hover:bg-ocTeal hover:text-gray-900 outline outline-ocTeal outline-offset-2 outline-2"
            href="/calculator"
          >
            Find a property to invest in
            <svg
              className="inline-block w-3 ml-2"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
            </svg>
          </a>
        </div>
        <div className="">
          <div className="flex flex-col lg:flex-row items-start">
            <div className="rounded drop-shadow-xl bg-white p-4 lg:mr-4 mb-4 lg:mb-0">
              <div className="py-2 mb-2 flex border-b border-gray justify-left">
                <h3 className="text-xl font-light text-ocBlue ">Your OC investment would now be worth <span className="text-3xl">£{Math.ceil(chartData.datasets[1].data[chartData.datasets[1].data.length - 1]).toLocaleString()}</span></h3>
              </div>
              <div className="flex flex-col align-center lg:w-[500px] md:w-[400px] w-[300px] items-start">
                <Line
                  options={options} 
                  data={chartData} 
                  redraw={true}
                />
              </div>
              <div className="py-2 mb-2 flex justify-left">
                <h3 className="text-lg font-light text-ocBlue ">Based on historical property price data for this location, the value of your capital would have increased <span className="text-2xl bold">{OCReturnPercent}%</span> vs. a tradition bank account that would have increased by <span className="text-2xl">{bankReturnPercent}%</span>.</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};